@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Medium'), local('BlenderPro-Medium'),
    url(./BlenderPro-Medium.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Thin Italic'), local('BlenderPro-ThinItalic'),
    url(./BlenderPro-ThinItalic.woff2) format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Heavy'), local('BlenderPro-Heavy'),
    url(./BlenderPro-Heavy.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Blender Pro Book';
    src: local('Blender Pro Book Italic'), local('BlenderPro-BookItalic'),
    url(./BlenderPro-BookItalic.woff2) format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Blender Pro Book';
    src: local('Blender Pro Book'), local('BlenderPro-Book'),
    url(./BlenderPro-Book.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Bold'), local('BlenderPro-Bold'),
    url(./BlenderPro-Bold.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Thin'), local('BlenderPro-Thin'),
    url(./BlenderPro-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Medium Italic'), local('BlenderPro-MediumItalic'),
    url(./BlenderPro-MediumItalic.woff2) format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Blender Pro';
    src: local('Blender Pro Bold Italic'), local('BlenderPro-BoldItalic'),
    url(./BlenderPro-BoldItalic.woff2) format('woff2');
    font-weight: bold;
    font-style: italic;
}
