@import './fonts/blenderProFont.css';

html {
    font-size: 10px;
}

@media screen and (min-width: 900px) and (-webkit-min-device-pixel-ratio: 1.25) {
    .windowsPlatform {
        font-size: 7.5px;
    }
}
