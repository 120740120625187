:root {
    /* COLORS */
    --cookie-consent-banner-colors-primary: #7454F9;
    --cookie-consent-banner-colors-primary-border: #fff;
    --cookie-consent-banner-colors-primary-content: #fff;
    --cookie-consent-banner-colors-secondary: none;
    --cookie-consent-banner-colors-secondary-border: none;
    --cookie-consent-banner-colors-secondary-content: #fff;
    --cookie-consent-banner-colors-background-body: rgba(25, 31, 34, 0.92);
    --cookie-consent-banner-colors-text: #fff;

    /* BORDER-RADIUS */
    --cookie-consent-banner-border-radius-buttons: 0;
    --cookie-consent-banner-border-radius-body: 0;

    /* SPACINGS */
    --cookie-consent-banner-spacings-container-padding-top: 1rem;
    --cookie-consent-banner-spacings-container-padding-left: 1rem;
    --cookie-consent-banner-spacings-container-padding-bottom: 1rem;
    --cookie-consent-banner-spacings-container-padding-right: 1rem;

    --cookie-consent-banner-spacings-body-padding-top: 1rem;
    --cookie-consent-banner-spacings-body-padding-left: 1.5rem;
    --cookie-consent-banner-spacings-body-padding-bottom: 1rem;
    --cookie-consent-banner-spacings-body-padding-right: 1.5rem;

    /* Z-INDEX */
    --cookie-consent-banner-z-index-container: 99;

    /* FONTS */
    --cookie-consent-banner-font-family-headline: inherit;
    --cookie-consent-banner-font-size-headline: 1.3rem;
    --cookie-consent-banner-font-family-body: inherit;
    --cookie-consent-banner-font-size-body: 1.3rem;
}

.banner > div > button {
    font-family: inherit;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: unset;
    text-transform: none;
    color: #7454F9;
    padding: 0 0.5rem;
    min-width: unset;
}

@media (min-width: 900px) {
    :root {
        /* SPACINGS */
        --cookie-consent-banner-spacings-body-padding-top: 3rem;
        --cookie-consent-banner-spacings-body-padding-left: 3rem;
        --cookie-consent-banner-spacings-body-padding-bottom: 3rem;
        --cookie-consent-banner-spacings-body-padding-right: 3rem;
        /* FONTS */
        --cookie-consent-banner-font-size-headline: 1.6rem;
        --cookie-consent-banner-font-size-body: 1.6rem;
    }
    .banner > div > button {
        font-size: 1.6rem;
    }
    .banner > div {
        padding-right: 20rem;
    }
}
